<template>
  <div class="issue-status">
    <v-toolbar flat>
      <v-btn icon @click="back">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title>Organización</v-toolbar-title>
    </v-toolbar>
    <v-container fluid>
      <v-row justify="center" class="mx-8">
        <base-error :error="error"></base-error>
      </v-row>
      <v-form v-model="isValid" ref="form" @submit="submit" class="new__issue-status__form ma-5">
        <v-row>
          <div class="title mb-3">Marca</div>
        </v-row>
        <v-row class="mb-0">
          <v-col>
            <v-text-field
              outlined
              label="Nombre"
              v-model="name"
              :error-messages="nameError"
              @keydown="nameError=null; error=null"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <div class="title mb-3">Personalización</div>
        </v-row>
        <v-row align="center mb-3">
          <v-col cols="4">
            <v-img :src="logoUrl"></v-img>
          </v-col>
          <v-col>
            <v-file-input
              outlined
              show-size
              class="ml-3"
              v-model="icon"
              accept="image/png, image/jpeg, image/bmp"
              label="Logo"
              placeholder="Elige un logo"
              :loading="isIconLoading"
              @change="onIconChange"
            ></v-file-input>
          </v-col>
        </v-row>
        <v-row class="mb-3">
          <v-col>
            <div class="grey--text text--darken-1 mb-2">Color primario</div>
            <v-color-picker flat v-model="primaryColor" width="31.25rem" hide-canvas></v-color-picker>
          </v-col>
          <v-col>
            <div class="grey--text text--darken-1 mb-2">Color secundario</div>
            <v-color-picker flat v-model="secondaryColor" width="31.25rem" hide-canvas></v-color-picker>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>
<script>
import rules from '@/constants/input-validation'
import { to } from '@/helpers'
import { errorMessage } from '@/mixins'
import {
  ISSUE_STATUS_CREATE_ISSUE_STATUS,
  ISSUE_STATUS_GET_ISSUE_STATUS_TYPES
} from '@/store/actions.type'
import { mapGetters } from 'vuex'
export default {
  name: 'IssueStatusNew',
  props: {},
  created() {
    this.getIssueStatusTypes()
  },
  data() {
    return {
      logoUrl:
        'https://dev-salt.s3.us-east-2.amazonaws.com/img/theme/app-logo.png',
      primaryColor: '#460A23',
      secondaryColor: '#460A23',
      rules,
      isValid: false,
      isLoading: false,
      description: '',
      descriptionError: '',
      name: 'Seguridad Perfecta',
      nameError: '',
      statusId: null,
      statusError: '',
      error: null
    }
  },
  computed: {
    ...mapGetters(['getIssueStatusIssueTypes'])
  },
  components: {},
  mixins: [errorMessage],
  methods: {
    back() {
      this.$router.go(-1)
    },
    async getIssueStatusTypes() {
      this.isLoading = true
      const [err] = await to(
        this.$store.dispatch(ISSUE_STATUS_GET_ISSUE_STATUS_TYPES)
      )
      if (err) {
        this.error = err
        this.isLoading = false
      } else {
        this.isLoading = false
      }
    },
    async submit(evt) {
      evt.preventDefault()
      const issueStatus = {
        name: this.name,
        description: this.description,
        color: this.color,
        statusType: {
          id: this.statusId
        }
      }
      this.isLoading = true
      this.error = null
      const [err] = await to(
        this.$store.dispatch(ISSUE_STATUS_CREATE_ISSUE_STATUS, issueStatus)
      )
      if (err) {
        this.error = err
        this.isLoading = false
        if (err.status === 422) {
          this.nameError = this.errorMessage(err, 'name')
          this.descriptionError = this.errorMessage(err, 'description')
        }
      } else {
        this.$router.push({ name: 'issue-status' })
      }
    }
  }
}
</script>
